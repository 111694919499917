.react-viewer {
  border: solid 1px #dee1e2;
}

.react-viewer-mask  {
  background-color: #f7fafc !important;
}

.react-viewer-navbar, .react-viewer-footer {
  background-color: #dee1e2 !important;
}

.react-viewer-list {
  white-space: nowrap;

  li {
    width: 60px !important;
  }

  img {
    width: 90px !important;
    object-fit: contain;
    background-color: #f7fafc;
  }
}

.react-viewer-attribute {
  color: #525f7f !important;
}