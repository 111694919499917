.matriculate-ethics {
  .react-datepicker-wrapper {
    flex-grow: 1;
    width: auto !important;

    input {
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}
