@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.marvel-device {
  .screen {
    background-color: #8c8b8b;
  }

  .notification {
    background-color: #ffffff;
    padding: 6px;
    margin: 6px;
    border-radius: 6px;
    font-family: 'Roboto';
    font-size: 14px;
    letter-spacing: 1px;

    .header {
      font-size: 11px;
      letter-spacing: .5px;
      line-height: 16px;
      margin-bottom: 3px;

      img {
        width: 20px;
        margin-right: 3px;
      }

      .source {
        font-weight: bold;
        color: #6d2c93;
      }

      .time {
        color: #909090
      }
    }

    .content {
      letter-spacing: .2px;
    }
  }
}

.form-control-alternative {
  box-shadow: none;
}