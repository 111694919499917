.loader.button {
  width: 32px !important;
  height: 24px !important;

  vertical-align: middle;

  margin-right: .685rem;
  padding-left: .5rem;
}

.loader.zip div {
  border: 3px solid #212529;
  border-color: #212529 transparent transparent transparent;
}

.loader.pdf div {
  border: 3px solid #fb6340;
  border-color: #fb6340 transparent transparent transparent;
}