#matriculate_photo {
  max-width: 100%;
  height: auto;

  margin-left: auto;
  margin-right: auto;

  border: solid 1px black;

  cursor: pointer;
}

.btn-group .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}