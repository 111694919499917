$error: #f75676;
$success: #28a745;

html {
  overflow-y: scroll !important; }

.img-logo {
  width: 170px; }

.auth-logo {
  width: 100vw;
  background: red !important;

  &__img {
    width: 250px; } }

.clickable {
  cursor: pointer; }

.hidden {
  display: none !important; }

.required {
  border: 1px solid $error !important; }

.is-valid {
  border: 1px solid $success !important; }

.input-eye {
  display: inline; }

.input-tip {
  margin-top: 5px;
  font-size: 12px;

  &--valid {
    color: $success; }
  &--invalid {
    color: $error; } }

.required-label {
  color: $error !important;
  font-size: 14px; }

.request-image {
  height: 400px;
  // border: 1px solid #0000FF

  &__main {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    max-width: 100%;
    max-height: 100%; }

  &--thumbnail {
    height: 80px;
    width: 120px;

    &__img {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      max-width: 100%;
      max-height: 100%; } } }

.attach-modal {
  // max-width: 70vw
  max-height: 90vh;

  & .modal-content {
 }    // background-color: black

  &--body {
    // width: 100vw
 }    // height: 85vh

  &--img {
    max-width: 100%; } }

.btn-search {
  @media(min-width: 992px) {
    margin-top: 33px; } }

.react-datepicker-wrapper {
  width: 100% !important; }

.remito-pdf {
  font-size: 20px;
 }  // color: #F40F02

.sessions ul {
  padding: 0 !important;

  & li {}
  list-style-type: none; }

#navbar-main {
  z-index: 10; }


.btn.disabled {
  box-shadow: none; }
